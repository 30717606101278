/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'device-ssd-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 8V4h6v4z"/><path pid="1" d="M4 0a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2zm0 1.5a.5.5 0 11-1 0 .5.5 0 011 0m9 0a.5.5 0 11-1 0 .5.5 0 011 0M3.5 11a.5.5 0 100-1 .5.5 0 000 1m9.5-.5a.5.5 0 11-1 0 .5.5 0 011 0M4.75 3h6.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75h-6.5A.75.75 0 014 8.25v-4.5A.75.75 0 014.75 3M5 12h6a1 1 0 011 1v2h-1v-2h-.75v2h-1v-2H8.5v2h-1v-2h-.75v2h-1v-2H5v2H4v-2a1 1 0 011-1"/>',
    },
});
